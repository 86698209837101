<template>
  <el-dialog title="管理设置" width="800px" :visible="setShow" :close-on-click-modal="false" @close='closeDialog'>
    <el-form ref="infoForm" :model="info" :rules="rules">
      <el-table class="global-table" :data="dataList">
        <el-table-column label="管理类型" prop="safeManageName">
          <template v-slot="{ row, $index }">
            <template v-if="$index === editIndex">
              <el-form-item prop="safeManageName">
                <el-input v-model="info.safeManageName" placeholder="请输入管理类型" :maxlength="10"></el-input>
              </el-form-item>
            </template>
            <template v-else>
              {{row.safeManageName}}
            </template>
          </template>
        </el-table-column>
        <el-table-column label="巡查执行人" prop="zxrUserNames">
          <template v-slot="{ row, $index }">
            <template v-if="$index === editIndex">
              <el-form-item prop="zxrUserIds">
                <el-select v-model="info.zxrUserIds" placeholder="请选择巡查执行人" filterable clearable multiple>
                  <el-option v-for="(item, index) in userList" :label="item.name" :value="item.userId" :key="index">
                    <div class="option-detail">
                      <p>{{item.name}}</p>
                      <p>{{item.deptName}}</p>
                    </div>
                  </el-option>
                </el-select>
              </el-form-item>
            </template>
            <template v-else>
              {{row.zxrUserNames && row.zxrUserNames.length > 0 ? row.zxrUserNames.join(',') : ''}}
            </template>
          </template>
        </el-table-column>
        <el-table-column label="问题处理人" prop="zgrUserNames">
          <template v-slot="{ row, $index }">
            <template v-if="$index === editIndex">
              <el-form-item prop="zgrUserIds">
                <el-select v-model="info.zgrUserIds" placeholder="请选择问题处理人" filterable clearable multiple>
                  <el-option v-for="(item, index) in userList" :label="item.name" :value="item.userId" :key="index">
                    <div class="option-detail">
                      <p>{{item.name}}</p>
                      <p>{{item.deptName}}</p>
                    </div>
                  </el-option>
                </el-select>
              </el-form-item>
            </template>
            <template v-else>
              {{row.zgrUserNames && row.zgrUserNames.length > 0 ? row.zgrUserNames.join(',') : ''}}
            </template>
          </template>
        </el-table-column>
        <el-table-column label="问题审核人" prop="fzrUserNames">
          <template v-slot="{ row, $index }">
            <template v-if="$index === editIndex">
              <el-form-item prop="fzrUserIds">
                <el-select v-model="info.fzrUserIds" placeholder="请选择问题审核人" filterable clearable multiple>
                  <el-option v-for="(item, index) in userList" :label="item.name" :value="item.userId" :key="index">
                    <div class="option-detail">
                      <p>{{item.name}}</p>
                      <p>{{item.deptName}}</p>
                    </div>
                  </el-option>
                </el-select>
              </el-form-item>
            </template>
            <template v-else>
              {{row.fzrUserNames && row.fzrUserNames.length > 0 ? row.fzrUserNames.join(',') : ''}}
            </template>
          </template>
        </el-table-column>
        <el-table-column label="操作" width="100px" fixed="right">
          <template v-slot="{ row, $index }">
            <el-button v-if="$index === editIndex" type="text" @click="onSave()">保存</el-button>
            <template v-else>
              <el-button type="text" @click="onEdit(row, $index)">编辑</el-button>
              <el-button type="text" @click="onDelete(row)">删除</el-button>
            </template>
          </template>
        </el-table-column>
      </el-table>
    </el-form>
    <el-button type="text" @click="onAdd">新增管理类型</el-button>
  </el-dialog>
</template>

<script>
export default {
  name: 'SafeSet',
  props: {
    setShow: Boolean
  },
  watch: {
    setShow (val) {
      if (val) {
        this.init()
      }
    }
  },
  data () {
    return {
      dataList: [],
      userList: [],
      editIndex: -1,
      info: {
        safeManageName: '',
        zxrUserIds: [],
        fzrUserIds: '',
        zgrUserIds: []
      },
      rules: {
        safeManageName: [
          { required: true, message: '请输入管理类型', trigger: ['blur', 'change'] }
        ],
        zxrUserIds: [
          { required: true, message: '请选择巡查执行人', trigger: ['blur', 'change'] }
        ],
        fzrUserIds: [
          { required: true, message: '请选择问题审核人', trigger: ['blur', 'change'] }
        ],
        zgrUserIds: [
          { required: true, message: '请选择问题处理人', trigger: ['blur', 'change'] }
        ]
      }
    }
  },
  created () {
    this.init()
  },
  methods: {
    init () {
      this.dataList = []
      this.loadData()
      this.loadUserList()
    },
    loadData () {
      this.$axios.get(this.$apis.patrol.selectAQGLUserConfigByList).then(res => {
        this.dataList = res || []
      })
    },
    loadUserList () {
      this.$axios.get(this.$apis.common.getRoleUserList).then(res => {
        this.userList = res || []
      })
    },
    onAdd () {
      if (this.editIndex !== -1) {
        this.$message.warning('请先保存')
      } else {
        this.editIndex = this.dataList.length
        this.dataList.push({ ...this.info })
      }
    },
    onEdit (row, index) {
      if (this.editIndex !== -1) {
        this.$message.warning('请先保存')
      } else {
        this.editIndex = index
        this.info = JSON.parse(JSON.stringify(row))
      }
    },
    onSave () {
      this.$refs.infoForm.validate().then(() => {
        const ind = this.dataList.findIndex(item => item.safeManageName === this.info.safeManageName)
        if (ind >= 0 && ind !== this.editIndex) {
          this.$message.warning('该管理类型已存在，请勿重复添加')
        } else {
          const url = this.info.safeManageId ? this.$apis.patrol.updateAQGLUserConfig : this.$apis.patrol.insertAQGLUserConfig
          this.$axios.post(url, this.info).then(() => {
            this.loadData()
            this.clearEditInfo()
          })
        }
      })
    },
    onDelete (row) {
      if (this.editIndex === -1) {
        this.$confirm(`确认删除管理类型【${row.safeManageName}】, 是否继续?`, '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          this.$axios.post(this.$apis.patrol.deleteAQGLDataUserConfig, {
            safeManageId: row.safeManageId
          }).then(() => {
            this.loadData()
          })
        })
      } else {
        this.$message.warning('请先保存')
      }
    },
    // 关闭
    closeDialog () {
      this.$emit('update:setShow', false)
      if (this.editIndex !== -1) {
        this.clearEditInfo()
      }
    },
    clearEditInfo () {
      this.info = {
        safeManageName: '',
        zxrUserIds: [],
        fzrUserIds: '',
        zgrUserIds: []
      }
      this.editIndex = -1
    }
  }
}
</script>

<style scoped lang="scss">
  .option-detail {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 300px;
    p {
      width: 45%;
      overflow: hidden;
      word-break: keep-all;
      white-space: nowrap;
      text-overflow: ellipsis;
      &:last-child {
        text-align: right;
      }
    }
  }
</style>
