<template>
  <div class="nodata-content">
    <img src="@/assets/img/noData.svg" alt="">
    <p>{{text}}</p>
  </div>
</template>

<script>
export default {
  name: 'NoData',
  props: {
    text: {
      type: String,
      default: '暂无数据'
    }
  },
  data () {
    return {}
  },
  computed: {
  },
  watch: {
  },
  created () {
    // do something
  },
  methods: {
  }
}
</script>

<style scoped lang="scss">
  .nodata-content {
    // width: calc(100vw - 220px);
    // height: calc(100vh - 142px);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    p {
      font-size: 16px;
      font-family: PingFangSC-Light, PingFang SC;
      font-weight: 300;
      color: #333333;
      line-height: 22px;
      margin-top: 27px;
    }
  }
</style>
